.App {
  text-align: center;
}

.App-header {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI,Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  margin: 0;
  color: white;
}

.App-chat-screenshot {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI,Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.App-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI,Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  margin: 50px 0px 0px 0px;
}

.App-waitlist {
  font-size: calc(10px + 2vmin);
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI,Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.App-navbar {
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 2vmin);
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI,Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  margin: 0;
  color: white;
}

.App-statement {
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI,Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  margin: 25px 50px 50px 50px;
  color: white;
}

.App-mission{
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI,Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  margin: 25px 50px 25px 50px;
  color: white;
}

.App-product-video {
  align-items: center;
  justify-content: center;
  width: 50%;
  margin: 50px 50px 25px 50px;
}

.support-platform:before {
    content: '';
    animation: rolling 6s linear infinite;
}
@keyframes rolling{
    0%{
        content: "TikTok";
    }
    50%{
        content: "Snap";
    }
    100%{
        content: "Youtube Shorts";
    }
}

@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-device-pixel-ratio: 3) {
  img {
    width: 50%;
    height: auto;
  }
}

@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-device-pixel-ratio: 2) {
    img {
      width: 50%;
      height: auto;
    }
}

@media (min-width: 480px) {
  img {
    width: 50%;
    height: auto;
  }
}

@media (min-width: 1024px) {
  img {
    width: 50%;
    height: auto;
  }
}

.product-video {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
}

.product-video iframe,
.product-video object,
.product-video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.footer {
  background-color: #f2f2f2;
  text-align: center;
  position: absolute;
  width: 95%;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI,Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}
