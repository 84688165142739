.sticky-note {
  width: 300px;
  height: 200px;
  padding: 16px;
  border: 2px solid #ffcc66;
  border-radius: 8px;
  background-color: #fff8dc;
  box-shadow: 3px 3px 3px #ccc;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI,Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  margin: 25px 50px 25px 50px;
}

.sticky-note-header {
  padding-bottom: 8px;
  font-size: 1.2rem;
  font-weight: bold;
}

.sticky-note-body {
  font-size: 0.9rem;
  line-height: 1.4;
  text-align: left;
}
